<template id="V-pdf">

  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="text-primary">Validador de PDF</h1>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col>
        <div class="my-5">
          Valida tus documentos firmados en formato PDF de acuerdo al estándar PAdES (ISO 32001)
          y verifica que la firma sea auténtica.
        </div>
      </vs-col>
    </vs-row>

    <div  v-if="!result">
      <input type="file" ref="fileInput" class="hidden" accept=".pdf" @change="handleClick">
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
        <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
        <span class="block">Arrastra o selecciona tu archivo PDF </span>
        <span class="block" v-if="pdf"><b>{{pdf.name}}</b></span>
      </div>
      <vs-button v-if="!loading" type="filled" @click.prevent="sendPdf" class="mt-5 block">Validar</vs-button>
      <vs-progress class="mt-5" v-if="loading" indeterminate color="primary">Validando ...</vs-progress>
    </div>

    <!-- Validation Results -->
    <transition name="fade">
      <div class="container" id="results" v-if="result">

        <!--todo: make component-->
        <div class="flex flex-wrap items-center justify-between">
          <h2 class="mb-base mr-3 text-primary">Resultado de tu validación</h2>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3 no-print" color="success" @click="printResult" type="filled">Imprimir reporte</vs-button>
          </div>
        </div>

        <!--Detalles del Documento-->
        <doc-details :name="result['filename']" :hash="result['hash']"/>

        <!-- Firmantes -->
        <vx-card id="signers">
          <vs-list-header
            icon="fingerprint"
            title="Firmantes"
            :color="result['signerResults'][0]['signatureValid']
                  ? 'success'
                  : 'danger'"
          >
          </vs-list-header>
          <signer v-for="signer in result['signerResults']" :key="signer['signerName']"
                  :name="signer['signerName']"
                  :digest-alg="signer['digestAlgorithm']"
                  :is-sig-valid="signer['signatureValid']"
                  :sig-date="signer['signatureDateTime']"
                  :cert="signer['certResult']"
                  :timestamp="signer['timestampResult']"
                  :check-timestamp="true">
          </signer>

        </vx-card>
        <div class="vx-row no-print">
          <vs-button class="mt-5 block mr-2" @click="restore" type="filled">Validar otro PDF</vs-button>
        </div>
      </div>
    </transition>

  </div>

</template>
<script>
import ValidatorService from '../service/validatorService'
export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      pdf: null,
      result: null,
      error: false,
      msgErr: '',
      loading: false
    }
  },
  methods: {

    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.alert('Solo se permite un archivo')
        return
      }
      const rawFile = files[0] //
      if (!this.isPdf(rawFile)) {
        this.alert('Solo se admiten archivos .PDF')
        return false
      }
      this.uploadFile(rawFile)
    },

    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      this.uploadFile(rawFile)
    },
    isPdf (file) {
      return /\.(pdf)$/.test(file.name)
    },

    uploadFile (file) {
      this.$refs['fileInput'].value = null
      this.pdf = file
    },

    async sendPdf () {
      if (!this.pdf) {
        this.alert('Debes seleccionar un PDF para validar')
        return false
      }
      const vm = this
      this.loading = true
      ValidatorService.validatePdf(this.pdf)
        .then(function (response) {
          vm.result = response.data
        })
        .catch(function (error) {
          vm.error = true
          console.log(error.response.data)
          vm.alert(error.response.data['description'])
        })
        .finally(function () {
          vm.loading = false
        })
    },
    alert (error) {
      this.$vs.dialog({
        color:'warning',
        title: 'Problema de validación',
        acceptText: 'Aceptar',
        text: `${error}`
      })
    },

    validateForm () {
      if (!this.pdf) {
        this.alert('Debes seleccionar un archivo PDF')
        return false
      }
      return true
    },
    restore () {
      this.pdf = null
      this.result = null
      this.error = false
    },
    printResult () {
      window.print()
    }
  }
}
</script>
